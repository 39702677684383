const NewAds = () => {
    return(
        <div>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7258253904826153"
     crossorigin="anonymous"></script>
{/* <!-- new display unit --> */}
<ins class="adsbygoogle"
     style={{display:"block"}}
     data-ad-client="ca-pub-7258253904826153"
     data-ad-slot="8203532534"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>
        </div>
    )
}
export default NewAds;